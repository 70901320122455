.environment-container {
    background-color: #010103;
    color: #fff;
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 50px;
    
  }
  
  .environment-heading {
    font-size: calc(2vw);
    margin-bottom: 20px;
  }
  
  .environment-subheading {
    font-size: calc(1.5rem);
    margin-bottom: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 450px;
  }

  
  .environment-button {
    background-color: #00cc66;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .environment-button:hover {
    background-color: #009944;
  }
  
  .environment-image {
    width: auto;
    max-width: 1300px;
    height: 45vh;
    margin-top: 30px;
  }
  