.roadmap {
  background-color:  #0f0c21;
  color: white;
  padding: 0px 0;
  padding-top:0px;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 600px;
  display: flex;
  /* align-items: center; */
  justify-content: center; /* Center the content horizontally */
  background-image: url('../../public/roadmap_bg.webp');
  
}

.roadmap-content {
  max-width: 1200px;
  width: 100%;
  margin: 0;
  margin-top:70px;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: left; /* Center text for headings */
  
}

.roadmap h2 {
  font-size: 48px;
  margin-bottom: 20px;
}

.roadmap-description {
  font-size: 18px;
  margin-bottom: 80px;
  max-width: 800px;
  text-align: left;
  
}

.feature-cards-container {
  overflow: visible; /* Make sure overflow is visible */
  margin: 0 -10px;
}


.feature-cards {
  display: flex;
  transition: transform 0.5s ease;
  width: calc(300px * 5 + 40px * 4); /* Adjust the total width */
}

.feature-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 30px;
  padding-top:15px;
  width: 300px;
  height: 430px;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.feature-card.active {
  background-color: white;
  color: #1e1f25;
}

.feature-card h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.feature-card p {
  font-size: 16px;
  margin-bottom: 15px;
  flex-grow: 1;
}

.comparison {
  font-size: 14px;
  color: #aaa;
  margin-top: auto;
}

.feature-card.active .comparison {
  color: #666;
}

.vs {
  display: inline-block;
  background-color: #3a3b45;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 8px;
}

.navigation-arrows {
  display: flex;
  /* justify-content: center; */
  margin-top: 40px;
}

.arrow {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 1640px) {
  .roadmap-content {
    padding: 0 40px;
  }
}

@media (max-width: 768px) {
  .roadmap-content {
    padding: 0 20px;
  }
  
  .feature-card {
    width: calc(100vw - 40px);
    height: auto;
    min-height: 200px;
  }
  
  .feature-cards {
    transform: none !important;
  }
}

.roadmap-cards-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 0 20px;
}

.roadmap-card {
  background-color: #1E2027;
  border-radius: 8px;
  padding: 20px;
  width: 18%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.roadmap-card h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.roadmap-card p {
  margin-bottom: 15px;
  font-size: 14px;
}

.roadmap-card img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
}

.roadmap-card .number {
  bottom: 10px;
  left: 10px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.3);
  font-weight: bold;
}
.roadmap {
  padding-bottom: 100px; /* Reduce this to allow cards to leak */
  overflow: visible; /* Allow content to overflow */
}

.roadmap-content {
  margin-bottom: 50px; /* Adjust to bring cards closer to content */
}

.roadmap-cards-wrapper {
  min-height: 800px;
  left: 0;
  right: 0;
  padding: 20px 0;
}

