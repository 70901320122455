.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  background-color: transparent;
  padding: 20px 0;
}

.header.scrolled {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  list-style: none;
  margin-right: 40px;
  padding: 0;

}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-family: 'Circular', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right .btn {
  background-color: #ffffff;
  color: #1e1f25;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  margin-right: 20px;
  font-family: 'Circular', sans-serif;
  font-size: 14px;
  font-weight: 550;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
}

.header-right .btn:hover {
  background-color: #1e1f25;
  color: #ffffff;
  border-color: #1e1f25;
}

.header-right .sign-in {
  text-decoration: none;
  color: #333;
  font-family: 'Circular', sans-serif;
  font-size: 15px;
  font-weight: 700;
}

/* Adjust Logo size */
.header-content > svg {
  width: 30%;
  height: auto;
}

/* Add padding to the body to prevent content from being hidden under the fixed header */
body {
  padding-top: 75px; /* Adjust this value based on your header's height */
}

/* Hover effects for nav links */
.nav-links a:hover {
  color: #007bff;
}


/* Hover effect for Sign In link */
.header-right .sign-in:hover {
  color: #007bff;
}

/* Responsive design */
@media (max-width: 1024px) {
  .header-content {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide nav links on smaller screens */
  }
  
  .header-right {
    margin-left: auto;
  }
}