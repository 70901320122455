.newsletter-container {
    position: relative;

    overflow: hidden;
    border-radius: 8px;
    text-align: left;
    color: white;
    max-width: 2000px;
    min-width:1000px;
    width:70vw;
    margin-left: auto;
    margin-right: auto;
    /* padding: 40px 24px; */
    padding-bottom:100px;
    background: linear-gradient(to bottom, #000000, #004ff8);
    height: 500px;
  }
  
  .overlay-noise {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../public/grains_bottom.png') bottom center no-repeat;
    background-size: cover;
    opacity: 0.2;
  }
  
  .overlay-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
  }
  
  .content {
    position: relative;
    z-index: 1;
    
  }
  
  .indicator {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .indicator-dot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: white;
    margin-right: 10px;
  }
  
  .indicator h6 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .content p {
    font-size: 1.25rem;
    font-weight: 500;
    max-width: 360px;
    margin-bottom: 20px;
    padding-left: 200px;
    padding-top:100px;
    color:white;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 360px;
  }
  
  .newsletter-form {
    display: flex;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
    margin-left:50%;
    margin-top:100px;
  }
  
  .newsletter-form input {
    flex-grow: 1;
    border: none;
    border-radius: 5px 0 0 5px;
    background: transparent;
    padding: 12px;
    font-size: 1rem;
    color: white;
    outline: none;
  }
  
  .newsletter-form input::placeholder {
    color: rgba(255, 255, 255, 0.45);
  }
  
  .newsletter-form button {
    border: none;
    border-radius: 0 5px 5px 0;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    padding: 0 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .newsletter-form button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .newsletter-form button span {
    font-size: 1.5rem;
  }
  