/* src/components/MainContent.css */
.main-content {
    /* padding: 40px 20px; */
    color: white;
    
  }
  
  .hero-section {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .hero-section h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .hero-section p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .btn-secondary {
    background-color: #61dafb;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    color: black;
    transition: all 0.3s ease;
  }
  
  .btn-secondary:hover {
    background-color: #50b7d8;
  }
  
  .features-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .feature {
    background-color: #2a2a2e;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    flex: 1;
    min-width: 250px;
    margin: 10px;
  }
  
  .feature h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .feature p {
    font-size: 1em;
  }
  
  .incubator-section {
    text-align: center;
    margin-top: 40px;
    background-color: #0f0c21;
  }
  
  .incubator-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    
  }
  
  

  .roadtree {
    background-color: #ecf2fe;
    padding-top:5vh;
    padding-bottom:5vh;
  }

.environment {
  background-color: #0a090e;
}

.intro-section {
  margin-top: 40px;
  width:70vw;
  height:100%;
  margin-top: 8vh;
  background-color:#ecf2fe;
  z-index: -1;
  width:70vw;
  display:flex;
  margin-left: auto;
  margin-right:auto;
}

