.mantle-governance {
    text-align: center;
    color: white;
    background-color: black;
    padding: 20px;
  }
  
  .mantle-sections {
    display: flex;
    justify-content: center;
    margin-top: 10em;
  }
  
  .mantle-section {
    border: 1px solid #333;
    border-radius: 8px;
    margin: 0 10px;
    padding: 30px;
    width: 680px;
    text-align: left;
    height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    font-family: 'Circular', sans-serif;
  }
  
  .mantle-section1 {
    background-image: url("../../public/governance.webp");
    background-position-y: -80px;
  }
  
  .mantle-section2 {
    background-image: url("../../public/resource_allocation.webp");
    background-position-y: -100px;
    
  }
  
  .mantle-content {
    position: absolute;
    top: 50%; /* Changed to start from the middle */
    left: 20px;
    right: 20px;
    transform: translateY(-10%); /* Adjusted to ensure content starts from mid-section */
    color: white;
    display: flex; /* Added to use flexbox for alignment */
    flex-direction: column; /* Added to arrange content vertically */
    justify-content: space-between; /* Added to space out content and position buttons at the bottom */
    height: 50%; /* Added to control the height of the content area */
    font-family: 'Circular', sans-serif;

  }
  .mantle-content > p {
    margin-top: 50px;
    font-size: 1.1em;
  }
  .mantle-buttons {
    margin-top: 20px;
  }
  
  .mantle-buttons button {
    background-color: #0f1b19;
    border: none;
    color: #69a5a0;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .mantle-buttons button:hover {
    background-color:#69a5a0;
    color: #0f1b19;
    scale:120%;
  }

  