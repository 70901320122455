/* src/components/IncubatorCard.css */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap');
/* src/components/IncubatorCard.css */
.incubator-card {
  /* background-color: #121014; */
  border-radius: 10px;
  padding: 5px 40px;
  margin: 10px;
  text-align: center;
  color: white;
  background-color: black;
  transition: transform 0.3s;
}

.incubator-card:hover {
  transform: scale(1.05);
}

.incubator-card-image {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  object-fit: contain;
}

.incubator-card-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}


.signal-ventures-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: white; /* White color */
  font-family: 'Orbitron', sans-serif; /* Futuristic sans-serif font */
}
