/* IntroGrid.css */
:root {
  --atmosphere-blue-light: #152c4e;
  --atmosphere-blue-dark: #0a192f;
  --text-color-light: #000;
  --text-color-dark: #fff;
}

.intro-grid {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 600px;
  height: 70vh;
  position: relative;
  margin-top: 50px;
  margin-bottom: 30px;
}

.canvas {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--atmosphere-blue-light);
  transition: background-color 0.3s ease;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dark .canvas {
  background-color: var(--atmosphere-blue-dark);
}

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.grain-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../public/grains_bottom.png');
  opacity: 0.25;
  mix-blend-mode: multiply;
  pointer-events: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content-left {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 1;
  color: var(--text-color-light);
  text-align: left;
  font-family: 'Circular', sans-serif !important;
}

.dark .content-left {
  color: var(--text-color-dark);
}

.content-right {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
  padding: 2rem;
  color: white;
  max-width: 600px;
  text-align: left;
  width:300px;
  font-family: 'Circular', sans-serif !important;
}

.dark .content-right {
  color: var(--text-color-dark);
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1.2;
}

p {
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.primary {
  background-color: white;
  color: var(--atmosphere-blue-dark);
  border: none;
}

.primary:hover {
  background-color: #f0f0f0;
}

.secondary {
  background-color: transparent;
  border: 1px solid currentColor;
  color: inherit;
}

.secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.arrow-down {
  display: block;
  margin-top: 1rem;
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  animation: blink 2s infinite;
}

.dark .arrow-down {
  color: white;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.dark-mode-toggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  border: 1px solid currentColor;
  color: inherit;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  z-index: 10;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  .buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    width: 100%;
  }

  .content-left,
  .content-right {
    padding: 1rem;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    right: 50%;
  }

  
}
