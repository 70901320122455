@import url('https://fonts.googleapis.com/css2?family=Circular:wght@400;700&display=swap');

.initiatives-container {
  background-color: #000;
  color: #fff;
  font-family: 'Circular', sans-serif;
  text-align: center;
  padding: 50px 20px;
}

.initiatives-container h1 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-weight: normal;
}

.initiatives-grid {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 100px;
  max-width: 1800px;
  height: 400px;
  margin: 0 auto;
}

.initiative-card {
  background-color: none;
  border-color: white;
  border-width: 10px;
  border-radius: 10px;
  padding: 20px;
  width: calc(33.33% - 14px);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
}

.initiative-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.initiative-card img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.initiative-card h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.initiative-card p {
  font-size: 1.4rem;
  margin-bottom: 20px;
  flex-grow: 1;
  line-height: 1.5;
}

.status-button {
  background-color: #e0f7fa;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  font-size: 1.1rem;
  width:30%;
  align-self: flex-start;
}

.incubator-section {
  text-align: center;
  margin-top: 60px;
  background-color: #000;
}

.incubator-section h1 {  
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-weight: normal;
  font-family: 'Circular', sans-serif;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1000px) {
  .initiatives-grid {
    flex-direction: column;
    align-items: center;
  }

  .initiative-card {
    width: 100%;
    max-width: 400px;
  }
}